import React from 'react';
import { Menu, Container } from 'semantic-ui-react';

function NavBar() {
  return (
    <Menu>
      <Container text>
        <Menu.Item>
          <i className='phone icon'></i>
        </Menu.Item>
        <Menu.Item header>Wer4u</Menu.Item>
        <Menu.Item position='right'>
          <img
           src='https://socialdots.sfo3.digitaloceanspaces.com/wer4u/Avatar.jpg'
           avatar= "true"
          />
        </Menu.Item>
        <Menu.Item>v.2603.1442</Menu.Item>
      </Container>
    </Menu>
  );
}

export default NavBar;
