import React, { createContext, useState, useEffect, useContext } from "react";
import { Device } from "twilio-client";
import SocketIo from "../utils/SocketIo";
import { getQueryVariable } from "../App";


const CandyDeviceContext = createContext();
export const CandyDeviceProvider = ({ children }) => {
  const [device, setDevice] = useState(null);
  const [pbxToken, setPbxToken] = useState(null); 
  const idAgente = getQueryVariable("idagente");
  const queueAgente = getQueryVariable("queue");
  const typeAgent = getQueryVariable("tipo");
  const [deviceReady, setDeviceReady] = useState(false);


  useEffect(() => {
    SocketIo.emit("tokenPbx", idAgente);
    SocketIo.emit("agente", idAgente, queueAgente, typeAgent);
    SocketIo.on("pbx-token", (data) => {
      setPbxToken(data.token); 
    });

    // Limpiar el listener cuando el componente se desmonte
    return () => {
      SocketIo.off("pbx-token");
    };
  }, [idAgente]); // Solo se ejecuta una vez cuando el componente se monta

  useEffect(() => {
    if (pbxToken) {
      const candyDevice = new Device(pbxToken /*, {debug: true}*/);
      setDevice(candyDevice);
      candyDevice.on('ready',()=>{
          setDeviceReady(true); // Ahora el dispositivo está listo
      });
      return () => {
        if (candyDevice) {
          candyDevice.destroy();
          //console.log("Device destruido");
        }
      };
    }
  }, [pbxToken]); 

  return (
    <CandyDeviceContext.Provider value={device}>
      {deviceReady ? children : null}
    </CandyDeviceContext.Provider>
  );
};

export const useCandyDevice = () => {
  return useContext(CandyDeviceContext);
};
