import React, { useState, useEffect ,useRef} from 'react';
import { Container, Button, Step } from 'semantic-ui-react';
import axios from '../utils/Axios';
import  { getQueryVariable } from '../App';
import socket from '../utils/SocketIo';
import NavBar from './NavBar';
import audioFile from '../sounds/wait.mp3';
import Timer from '../components/timer.js';
import { useCandyDevice } from '../context/CandyDeviceProvider.js';
          //tel           //compainNumber 
function Outbound({ idAgente,setHangUpAgent,endPointUser,queueAgente,IDCASO}) {
  const tel =  getQueryVariable("To");
  const compainNumber = getQueryVariable("From");
  const [callSid, setCallSid] = useState(null);
  const [isCalling, setIsCalling] = useState(false);
  const [currentTel, setCurrentTel] = useState(tel);
  const audio = useRef( new Audio(audioFile));
  const [timer, setTimer] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const device  = useCandyDevice();
  const [call, setCall] = useState();
  // funcion para enviar al webservices de gina
  async function postJSONnew(data) {
    try {
      const response = await fetch(
        endPointUser,
        {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
          
        }
      );
      const result = await response.json();
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
      if (device) {
        device.on('incoming', (incomingConnection) => {
          setCall(incomingConnection);
          incomingConnection.accept();
          const statusCall = incomingConnection.status();
            if(statusCall === "connecting"){
              audio.current.pause();
              setTimer(true)
            }
          incomingConnection.on('disconnect', () => { 
              console.log('Llamada desconectada');
              setHangUpAgent(false); //para desmontar el componente
          });
        });
      }
    }, [device]);

  //   //hangUpCall
  // useEffect(() => {
  //  if(stopAudio){
  //   audio.current.pause();

  //  }
  // }, [stopAudio]);


  const handleCallClick = () => {
    if (currentTel && idAgente) {   
      //requestMicrophonePermission(); 
      axios
        .post(`/voice-callOut-auto`, {
          To: currentTel,
          Agente: idAgente,
          From: compainNumber,
          queue: queueAgente,
          idCaso: IDCASO
        })
        .then((response) => {
          // Manejar la respuesta del servicio
          //console.log('EL sid de la llamada es:', response.data);
          audio.current.play();
          setCallSid(response.data); // Actualizar el estado con el SID de la llamada
          socket.emit('call-sid', response.data, socket.id);
          // aquí tengo que mandar llamada el webservices de gina!!!!
          const dataCall = {
            EVENTO: 'update-callsid',
            IDAGENTE: idAgente,
            CallSid: response.data,
            COMPANYNUMBER: compainNumber,
            CLIENTNUMBER: currentTel,
            SKILL: '',
            IDCASO: '',
          };
          setTimeout(()=>{
            window.parent.postMessage(dataCall, '*');
           }, 1000);
         // console.log('Datos para Gina de update-callsid: ', dataCall);
          // comentado para pruebas
          // console.log(`DataCall`, dataCall)
           postJSONnew(dataCall);
        })
        .catch((error) => {
          // Manejar los errores aquí
          console.error('Error al redireccionar la llamada:', error);
        });
    } else {
      console.log('Faltan datos necesarios para manejar la llamada.');
    }
    setIsCalling(true);
  };

  const handleHangupClick = () => {
    if (isCalling && callSid) {
       audio.current.pause();
      axios
        .post(`/hang-up-call`, {
          CallSid: callSid,
          Agente: idAgente,
        })
        .then((response) => {
          //console.log('La llamada ha sido colgada:', response.data);
         
          
        })
        .catch((error) => {
          // Manejar los errores
          console.error('Error al colgar la llamada:', error);
        });
      setIsCalling(false); // Cambiar el estado a false para indicar que la llamada ha sido colgada.
    }
  };
  const handleMuteClick = () => {
    if (call) {
      call.mute(true);
      setIsMuted(true);
    } else {
      console.log('No hay una llamada activa.');
    }
  };
  
  const handleUnmuteClick = () => {
    if (call) {
      call.mute(false); 
      setIsMuted(false);
    } else {
      console.log('No hay una llamada activa.');
    }
  };
  return (
    <div>
      <NavBar />
      <Container>
        <Step.Group fluid>
          <Step
            icon='phone'
            title='Realizar llamada'
            description={'Número a marcar: ' + currentTel}
            active={!isCalling}
            disabled={isCalling}
            onClick={handleCallClick}
          />

          <Step
            icon='headphones'
            title='Colgar'
            description='Colgar la llamada'
            onClick={handleHangupClick} // Agregar la función de clic para colgar la llamada
            disabled={!isCalling} // Deshabilitar si no se está realizando una llamada
          />
           <Step
          icon={isMuted ? 'microphone slash' : 'microphone'}
          title={isMuted ? 'Activar' : 'Silenciar'}
          disabled={!isCalling} // Deshabilitar si no se está realizando una llamada
          onClick={isMuted ? handleUnmuteClick : handleMuteClick}
          />
            <Step
            icon='clock'
            title='Tiempo'
          />
         {timer && <Timer />} 
        </Step.Group>
      </Container>
    </div>
  );
};

export default Outbound;
