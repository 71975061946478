import React, { useState, useEffect } from "react";
import { Container, Step } from "semantic-ui-react";
import socket from "../utils/SocketIo";
import { handleHangupClick } from "../utils/hangUpCall.js";

function CallAnswerRedirect({ respaldo, from, callStatus, idAgente ,device, setIsCallActive}) {
  const [callHungUp, setCallHungUp] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [call, setCall] = useState();

  //console.log(`ELL CALLLSIDD en callAnswerRedirect `, respaldo, from, callStatus,idAgente, `===== DEVICEEEE====`, device);

  function answerCall(sid) {
    socket.emit("answer-call", { sid }, idAgente);
    setIsCalling(true);
  }

  useEffect(() => {
    if (device) {
      device.on("incoming", (incomingConnection) => {
        setCall(incomingConnection);
        incomingConnection.accept();
        const statusCall = incomingConnection.status();
        if (statusCall === "connecting") {
        }
        incomingConnection.on("disconnect", () => {
          console.log("Llamada desconectada");
          setIsCallActive(false);
        });
      });
    }
  }, [device]);

  const handleHangup = () => {
    if (!callHungUp) {
      handleHangupClick(respaldo, idAgente);
      setCallHungUp(true);
      setIsCallActive(false);
    }
  };

  const handleMuteClick = () => {
    if (call) {
      call.mute(true);
      setIsMuted(true);
    } else {
      console.log('No hay una llamada activa.');
    }
  };

  const handleUnmuteClick = () => {
    if (call) {
      call.mute(false); 
      setIsMuted(false);
    } else {
      console.log('No hay una llamada activa.');
    }
  };

  return (
    <div>
      <Container>
        <Step.Group fluid>
          <Step
            icon="phone"
            title="Numero Cliente"
            description={from}
            active={callStatus === "ringing"}
            completed={callStatus !== "ringing"}
          />
          <Step
            icon="phone"
            title="Contestar"
            description="Cliente en cola"
            active={callStatus === "enqueue"}
            disabled={callStatus === "ringing"}
            onClick={() => answerCall(respaldo)}
          />
          <Step
            icon="headphones"
            title="Colgar"
            disabled={!isCalling && respaldo}
            description="Colgar la llamada"
            onClick={handleHangup}
          />
          <Step
            icon={isMuted ? "microphone slash" : "microphone"}
            title={isMuted ? "Activar" : "Silenciar"}
            disabled={!isCalling && respaldo} // Deshabilitar si no se está realizando una llamada
            onClick={isMuted ? handleUnmuteClick : handleMuteClick}
          />
        </Step.Group>
      </Container>
    </div>
  );
}

export default CallAnswerRedirect;
