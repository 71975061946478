import React, { useState, useEffect } from 'react';
import { Container, Button, Step } from 'semantic-ui-react';
import axios from '../utils/Axios';
import socket from '../utils/SocketIo';
import NavBar from './NavBar';
import audioFile from '../sounds/wait.mp3';
import Timer from '../components/timer.js';
import { haciaGinaEndCallOut } from '../utils/gina';
import { useCandyDevice } from '../context/CandyDeviceProvider.js';


function Out({ tel, idAgente, compainNumber, caseCompain,setHangUpAgent,endPointUser,queueAgente}) {
  const [callSid, setCallSid] = useState(null);
  const [isCalling, setIsCalling] = useState(false);
  const [currentTel, setCurrentTel] = useState(tel);
  const audio = new Audio(audioFile);
  const [isMuted, setIsMuted] = useState(false); // Estado para el micrófono inicialmente en silencio
  const [timer, setTimer] = useState(false);
  const device  = useCandyDevice();
  const [call, setCall] = useState();

  //console.log(`Endpoint User` , endPointUser)
  //console.log(`Number company  ` , compainNumber , `Number a marcar` ,tel )
  //console.log(`llegando el valor de OUTTTTT `, tel, idAgente, compainNumber, device,endPointUser,sipValue);
  async function postJSONnew(data) {//nueva outbound
    try {
      const response = await fetch(
        endPointUser,
        {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        }
      );

      const result = await response.json();
    } catch (error) {
      console.error('Error:', error);
    }
  }
  useEffect(() => {
    if (device) {
      device.on('incoming', (incomingConnection) => {
        setCall(incomingConnection);
        incomingConnection.accept();
        const statusCall = incomingConnection.status();
          if(statusCall === "connecting"){
            audio.pause();
            setTimer(true)
          }
        incomingConnection.on('disconnect', () => { //ver si podemos desmontar el componente desde aqui
            console.log('Llamada desconectada');
        });
      });
    }
    // No es necesario destruir el device aquí, ya que se maneja en el proveedor
  }, [device]);
  
  const handleCallClick = () => {
    if (currentTel && idAgente) {   
      console.log('Dispositivo listo');
      audio.play();
      axios
        .post(`/voice`, {
          To: currentTel,
          Agente: idAgente,
          From: compainNumber,
          queue: queueAgente,
        })   //callsid
        .then((response) => {
          setCallSid(response.data);  //CallSid
          socket.emit(`call-sid-out`,response.data,socket.id);
          const dataCall = {
            EVENTO: 'update-callsid',
            IDAGENTE: idAgente,
            CallSid: response.data,
            COMPANYNUMBER: compainNumber,
            CLIENTNUMBER: currentTel,
            SKILL: '',
            IDCASO: '',
          };
          setTimeout(()=>{
            window.parent.postMessage(dataCall, '*');
           }, 1000);
          console.log('Datos para Gina de update-callsid: ', dataCall);
          // comentado para pruebas
          postJSONnew(dataCall);
        })
        .catch((error) => {
          console.error('Error al redireccionar la llamada:', error);
        });
    } else {
      console.log('Faltan datos necesarios para manejar la llamada.');
    }
    setIsCalling(true);
  };

  const handleHangupClick = () => {
    if (isCalling && callSid) {
       audio.pause();
      axios
        .post(`/hang-up-call`, {
          CallSid: callSid,
          Agente: idAgente,
        })
        .then( async (response) => {
          setHangUpAgent(false);
         await haciaGinaEndCallOut(idAgente,callSid,currentTel,compainNumber,queueAgente,caseCompain);
          //window.location.reload();
        })
        .catch((error) => {
          console.error('Error al colgar la llamada:', error);
        });
      setIsCalling(false); // Cambiar el estado a false para indicar que la llamada ha sido colgada.
    }
  };

  useEffect(() => {
    setCurrentTel(currentTel);
  }, [currentTel]);

  const handleMuteClick = () => {
    //const activeConnection = callDevice.activeConnection();
    if (call) {
      call.mute(true);
      setIsMuted(true);
    } else {
      console.log('No hay una llamada activa.');
    }
  };
  
  const handleUnmuteClick = () => {
    if (call) {
      call.mute(false); // Desmutear el micrófono
      setIsMuted(false);
    } else {
      console.log('No hay una llamada activa.');
    }
  };

  return (
    <div>
      <NavBar />
      <Container>
        <Step.Group fluid>
          <Step
            icon='phone'
            title='Realizar llamada'
            description={'Número a marcar: ' + currentTel}
            active={!isCalling}
            disabled={isCalling}
            onClick={handleCallClick}
          />

          <Step
            icon='headphones'
            title='Colgar'
            description='Colgar la llamada'
            onClick={handleHangupClick} // Agregar la función de clic para colgar la llamada
            disabled={!isCalling} // Deshabilitar si no se está realizando una llamada
          />
           <Step
          icon={isMuted ? 'microphone slash' : 'microphone'}
          title={isMuted ? 'Activar' : 'Silenciar'}
          disabled={!isCalling} // Deshabilitar si no se está realizando una llamada
          onClick={isMuted ? handleUnmuteClick : handleMuteClick}
          />
            <Step
            icon='clock'
            title='Tiempo'
          />
           {timer && <Timer />} 
        </Step.Group>
      </Container>
    </div>
  );
};

export default Out;
