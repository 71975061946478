import React, { useEffect, useRef, useState } from "react";
import Login from "./components/Login";
import { useImmer } from "use-immer";
import axios from "./utils/Axios";
import socket from "./utils/SocketIo";
import useLocalStorage from "./hooks/useLocalStorage";
import CallCenter from "./components/CallCenter";
import Out from "./components/out";
import Outbound from "./components/outbound";
import CallAnswerRedirect from "./components/callAnswerRedirect";
import NavBar from "./components/NavBar";
import audioFile from "../src/sounds/wait.mp3";
// recibir parámetros de gina
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { processEndPointUser } from "../src/utils/gina.js";
import * as Pbx from "twilio-client";
import { CandyDeviceProvider } from "./context/CandyDeviceProvider.js";
import {
  haciaGinaNewCall,
  haciaGinaNewCallTransfer,
  haciaGinaEndCallOut,
  haciaGinaEndCall,
  haciaGinaEndCallTransfer,
  haciaGinaEndCallOutound,
  getURI,
} from "./utils/gina";
import { Socket } from "socket.io-client";
import { current } from "immer";
// Por ejemplo, en tu componente o archivo de configuración
let IDCASO;
let IDLLAMADA;

// funcion para obtener el querystring
export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}
let i = 1;
// funcion para enviar al webservices de gina

function App() {
  const callSidInbound = useRef(null);
  const [callSidNewCall,setCallSidNewCall] = useState()
  //new Call-  inbound
  const [newCall, setIsNewCallActive] = useState(false);
  const [endCall, setEndCallInbound] = useState(false);
  const encallInboundSid = useRef(null);
  const [From, setFrom] = useState();
  const [callStatus, setCallStatus] = useState();
  const [callSid, setCallSid] = useState(); // id llamada nueva
  const [callSidOutbound,setCallSidOutbound] = useState();
  const [callStatusOutbound, setCallStatusOutbound] = useState();
  const [To, setTo] = useState();
  const [queue, setQueue] = useState();

  const [idCasoTransfer, setIdCasoTransfer] = useState();
  //const [storedToken, setStoredToken] = useLocalStorage("wexToken", null);
  const [device, setDevice] = useState();
  //`
  const [socketAgenteOutbound, setSocketAgentOutbound] = useState();
  const [socketAgentCallOutAuto, setSocketAgentOutboundAuto] = useState();
  const [hangupAgent, setHangUpAgent] = useState(true);

  //transfer

  const [callSidTransfer, setCallSidTransfer] = useState();
  const [isCallActive, setIsCallActive] = useState(false);
  const [skill, setSkillDestino] = useState(); // gaurda el queue que nos manda gina
  const [callTransfer, setCallTransfer] = useState();
  const [callStatusTransfer,setCallStatusTransfer] = useState();
  const [endCallTransfer, setEndCallTransfer] = useState(false);
  ///
  const [numberClient, setClientNumber] = useState();
  const [compainNumber, setNumberCompain] = useState();
  const [idCaseCompain, setIdCaso] = useState();
  const [idAgenteCompain, setIdAgente] = useState();
  const [respaldo, setRespaldo] = useState();
  const [out, setOut] = useState();
  const [endCallOutAuto, setEndcallOutAuto] = useState();
  const [outAuto, setOutAuto] = useState();
  const [endCallOut, setEndCallOut] = useState();
  const [callStatusOut, setCallStatusOut] = useState();
  const [typeCall, setTypeCall] = useState();
  // recibo idagente de la url de Gina
  const idAgente = getQueryVariable("idagente"); // comparar este id agente que sea igual al del bakend
  const queueAgente = getQueryVariable("queue");
  const typeAgent = getQueryVariable("tipo");
  const ToURI = getQueryVariable("To");
  const FromURI = getQueryVariable("From");
  const IDCASO = getQueryVariable("idcaso");

  const endPoint = getQueryVariable("env");
  const [endPointUser, setEndPoint] = useState();
  const tel = numberClient;
  const caseCompain = idCaseCompain;
  const [pbxToken, setPbxToken] = useState();
  const [showOutbound, setShowOutboundAuto] = useState(false);
  const [calls, setCalls] = useImmer({
    calls: [],
  });
  useEffect(() => {
    if (endPointUser) {
      processEndPointUser(endPointUser);
    }
  }, [endPointUser]);

  useEffect(() => {
    if (socketAgenteOutbound) {
    }
  }, [socketAgenteOutbound]);

  useEffect(() => {
    if (callStatus === "completed") {
      haciaGinaEndCall(idAgente, callSidInbound.current, To, From, queue);
    } else if (callStatus === "in-progress") {
      // console.log(
      //   `ANTES DE MANDAR A GINA  llamada In PROGRESS END-CALL-Transfer`
      // );
      //checar si funciona sin descomentar esta linea 
      //haciaGinaEndCallTransfer(idAgente,callSidTransfer,To,From,skill,idCasoTransfer);
    }
  }, [endCall]);
  
  useEffect(() => {
    if (callStatus === "completed") {
      haciaGinaEndCall(idAgente, encallInboundSid, To, From, queue);
    }
  }, []);

  //CALL-OUT-AUTO URL
  useEffect(() => {
    if (callStatusOutbound === "completed") {
      console.log(`END-CALL-out-auto`);
      const sendGinaDataCallOutbound = async() =>{
        await haciaGinaEndCallOutound(
          idAgente,
          callSidOutbound,
          ToURI,
          FromURI,
          queue,
          idCaseCompain
        );
      }
      sendGinaDataCallOutbound();
    }
  }, [endCallOutAuto]);

  useEffect(() => {
    if (callStatusTransfer === "completed") {
      haciaGinaEndCallTransfer(
        idAgente,
        callSidTransfer,
        To,
        From,
        skill,
        idCasoTransfer
      );
    }
  }, [endCallTransfer]);

  useEffect(() => {
    if (callStatusOut === "completed") {
      //console.log(`ANTES DE MANDAR A GINA END-CALL-Out`);
      const sendDataCallOut = async()=>{
        await haciaGinaEndCallOut(
          idAgente,
          endCallOut,
          numberClient,
          compainNumber,
          skill,
          caseCompain
        );
      }
    sendDataCallOut();
    }
  }, [endCallOut]);

  useEffect(() => {
    const executeNewCallTransfer = async () => {
      if (isCallActive && callTransfer == true) {
        await haciaGinaNewCallTransfer(
          idAgente,
          callSidTransfer,
          To,
          From,
          skill,
          idCasoTransfer
        );
      }
    };
    executeNewCallTransfer();
  }, [callSidTransfer]);




  useEffect(() => {
    const executeNewCall = async () => {
      if (newCall && callTransfer === false) {
        // console.log(
        //   `Antes de mandar A GINAAAAAA`,
        //   idAgente,
        //   callSid,
        //   To,
        //   From,
        //   queue
        // );
       await haciaGinaNewCall(idAgente, callSidNewCall, To, From, queue); 
      }
    };
    executeNewCall();
  }, [callSidNewCall]);

  useEffect(() => {
    if (callStatus === "completed") {
      console.log(`Colgando LLamada OUTBOUNDD`);
    }
  }, [socketAgenteOutbound]);

  useEffect(() => {
    socket.emit("endPointUser", endPoint);
  }, [endPoint]);
  socket.on("connect", () => {
    console.log("Socket conectado", socket.id);
   //solo se registra el agente de tipo out
   //no es necesario pedir el token para out aqui solo en context
    if(typeAgent === "out"){ 
      socket.emit("agente", idAgente, queueAgente, typeAgent);
    }
    //solo se pide tokens para todos aquellos que son inbound
    if (queueAgente && typeAgent!="outbound" &&  typeAgent != "out") { 
      socket.emit("agente", idAgente, queueAgente, typeAgent);
      socket.emit('tokenPbx', idAgente);
      socket.on('pbx-token', (data) => {
        setPbxToken(data.token);  
        
      });
    }
  });
//para inbund
  useEffect(() => {
    if (pbxToken &&  typeAgent!="outbound" && typeAgent != "out" ) {
      //console.log(`Token recibido del backend ` , pbxToken)
      connectPbxVoiceClient(pbxToken);
    }
  }, [pbxToken]);
  function connectPbxVoiceClient(pbxToken) {
    const devicePBX = new Pbx.Device(pbxToken);
    setDevice(devicePBX);
  }
  useEffect(() => {
    socket.on("disconnect", () => {
      socket.emit("delete-database", idAgente); // Corregir el nombre del evento aquí
      console.log("Socket desconectado");
    });

    socket.on("assigned-EndPoint", (endPoint) => {
      setEndPoint(endPoint);
    });
    
    socket.on('call-transfer', (newCall, queue, socketId, callTransfer, IDCASO, respaldo) => {
      const { CallSid, CallStatus, To, From } = newCall;
      
      if (socket.id === socketId) {
        setCallSidTransfer(CallSid);
        setRespaldo(respaldo);
        //console.log(`CALL SID TRANSFERRR`, respaldo);
        setSkillDestino(queue);
        setCallTransfer(true);
        setCallStatus(CallStatus);
        setIdCasoTransfer(IDCASO);
        setFrom(From);
        setTo(To);
        console.log(`transfer a: `, socketId);
        setIsCallActive(true); 
      }
    });

    socket.on(
      "call-out",
      (
        { data: { IDCASO, IDAGENTE, SKILL, CLIENTNUMBER, COMPANYNUMBER } },
        socketAgentOutbound
      ) => {
        setHangUpAgent(true);
        if (
          socket.id === socketAgentOutbound &&
          idAgente === IDAGENTE &&
          queueAgente === SKILL
        ) {
          setSocketAgentOutbound(socketAgentOutbound);
          setCallStatusOut(callStatus);
          setClientNumber(CLIENTNUMBER);
          setNumberCompain(COMPANYNUMBER);
          setIdCaso(IDCASO);
          setIdAgente(IDAGENTE);
          setSkillDestino(SKILL);
          // console.log(`Socket Agente: `, socket.id, `Socket a asignar llamada : `, socketAgentOutbound);
          setOut(true);
        }
      }
    );

    socket.on("call-out-auto", (socketAgentOutbound) => {
      setHangUpAgent(true);
      if (socket.id === socketAgentOutbound) {
        setSocketAgentOutboundAuto(socketAgentOutbound);
        setClientNumber(ToURI);
        setNumberCompain(FromURI);
        setIdAgente(idAgente);
        setSkillDestino(queueAgente);

        setOutAuto(true);
      }
    });

    // evento al recibir nueva llamada desde el server
    socket.on("call-new", (newCall, queue, socketId, callTransfer, IDCASO) => {
      console.log(`llamada nueva`)
      const { CallSid, CallStatus, To, From } = newCall; // propiedades dentro de newCall
      setCallStatus(CallStatus);
      setCallTransfer(callTransfer);
      //console.log(`CALL NEWW  ` , queue,socketId,callTransfer,IDCASO )
      if (
        queue === queueAgente &&
        socket.id === socketId &&
        callTransfer === false
      ) {
        callSidInbound.current = CallSid
        setCallSidNewCall(CallSid);
        setQueue(queue);
        setTo(To);
        setFrom(From);
        setIsNewCallActive(true);
      }
    });
    socket.on(
      "call-status-changed",
      (
        { data: { CallSid, CallStatus, To, From } },
        socketId,
        queue,
        typeCall,
        transfer
      ) => {
        if (
          socketId === socket.id &&
          queue === queueAgente &&
          typeCall === "inbound" &&
          CallStatus === "completed" &&
          transfer === false &&
          callSidInbound.current === CallSid
        ) {
          setCallStatus(CallStatus);
          setIsNewCallActive(false);
          //console.log(`Valor endCall de llamada entrante `, endCall);
          console.log(`El callSid es `, CallSid)
          setEndCallInbound(CallSid); 
          //encallInboundSid.current = CallSid;
        }
        if (socketId === socket.id && transfer) {
          // colgado de transferencia
          setCallStatusTransfer(CallStatus);
          setEndCallTransfer(CallSid);
        }
        if (CallStatus === "in-progress" && socket.id === socketId) {
          setCallStatus(CallStatus);
          setIsNewCallActive(false);
          setEndCallInbound(CallSid);
        }
        if (socket.id === socketId && typeCall === "outbound-auto-api") {
          //colgando llamada de outbound-automatico (url)
          setCallSidOutbound(CallSid);
          setCallStatusOutbound(CallStatus);
          setOutAuto(false);
          setEndcallOutAuto(CallSid);
        }
        if (socketId === socket.id && typeCall === "outbound-api") {
          // colgando la llamada de tipo out
          setTypeCall(typeCall);
          setCallStatusOut(CallStatus);
          setEndCallOut(CallSid);
          setOut(false);
        }
      }
    );

    socket.on("enqueue", ({ data: { CallSid } }, queue) => {
      setCalls((draft) => {
        const index = draft.calls.findIndex(
          ({ CallSid }) => CallSid === CallSid
        );
        if (index === -1) {
          return;
        }
        draft.calls[index].CallStatus = "enqueue";
      });
    });
    return () => {};
  }, []);
  
  useEffect(() => {
    if (FromURI && ToURI) {
      setShowOutboundAuto(true);  
    } else {
      setShowOutboundAuto(false); 
    }
  }, [FromURI, ToURI]); 


  return (
    <div>
      {/* Agregar otras condiciones para mostrar componentes basados en otras variables */}
      {idAgenteCompain === idAgente && tel && out && hangupAgent ? (
        <CandyDeviceProvider>
          <Out
            tel={tel}
            idAgente={idAgente}
            compainNumber={compainNumber}
            caseCompain={caseCompain}
            setHangUpAgent={setHangUpAgent}
            endPointUser={endPointUser}
            queueAgente={queueAgente}
          />
        </CandyDeviceProvider>
      ) : (
        <div>
          {newCall ? (
            <CallCenter
              callSidNewCall={callSidNewCall}
              from={From}
              To = {To}
              queueAgente = {queueAgente}
              callStatus={callStatus}
              idAgente={idAgente}
              device={device}
              setIsNewCallActive = {setIsNewCallActive}
            />
          ) : (
            <div>
              {showOutbound  && hangupAgent/* && idAgenteCompain === idAgente*/ ? (
                <CandyDeviceProvider>
                  <Outbound
                    idAgente={idAgente}
                    setHangUpAgent={setHangUpAgent}
                    endPointUser={endPointUser}
                    queueAgente={queueAgente}
                    IDCASO={IDCASO}
                  />
                </CandyDeviceProvider>
              ) : (
                <NavBar />
              )}
            </div>
          )}
        </div>
      )}
      {/* Mostrar los botones de respuesta y colgar si isCallActive es true o skill es igual a queueAgente */}
      <div>
      {isCallActive && skill === queueAgente && (
          <CallAnswerRedirect
            respaldo={respaldo}
            from={From}
            callStatus={callStatus}
            idAgente={idAgente}
            device={device}
            setIsCallActive = {setIsCallActive}
          />
      )}
      </div>
    </div>
  );
}

export default App;
