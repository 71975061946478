import React, { useState, useEffect, useRef } from "react";
import NavBar from "./NavBar";
import { Container, Step } from "semantic-ui-react";
import { haciaGinaEndCall } from "../utils/gina.js";
import socket from "../utils/SocketIo.js";
function CallCenter({
  callSidNewCall,
  from,
  To,
  queueAgente,
  callStatus,
  idAgente,
  device,
  setIsNewCallActive,
}) {
  const [callHungUp, setCallHungUp] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const call = useRef(null);
  // console.log(
  //   `DATA en CALLCENTER `,
  //   callSidNewCall,
  //   from,
  //   callStatus,
  //   idAgente,
  //   setIsNewCallActive,
  //   To,
  //   queueAgente
  // );

  useEffect(() => {
    if (device) {
      device.on("incoming", (incomingConnection) => {
        call.current = incomingConnection;
        incomingConnection.accept();
        const statusCall = incomingConnection.status();
        if (statusCall === "connecting") {
        }
        incomingConnection.on("disconnect", () => {
          setIsNewCallActive(false);
        });
      });
    }
  }, [device]);
  function answerCall(sid) {
    // call.current.accept();
    socket.emit('answer-call', { sid }, idAgente);
    setIsCalling(true);
  }

  const handleHangup = () => {
    if (!callHungUp) {
      call.current.disconnect();
      haciaGinaEndCall(idAgente, callSidNewCall, To, from, queueAgente, "");
      setCallHungUp(true);
      setIsNewCallActive(false);
    }
  };

  const handleMuteClick = () => {
    if (call.current) {
      call.current.mute(true);
      setIsMuted(true);
    } else {
      console.log("No hay una llamada activa.");
    }
  };
  const handleUnmuteClick = () => {
    if (call.current) {
      call.current.mute(false);
      setIsMuted(false);
    } else {
      console.log("No hay una llamada activa.");
    }
  };

  return (
    <div>
      <NavBar />
      <Container>
        <Step.Group fluid>
          <Step
            icon="phone"
            title="Numero Cliente"
            description={from}
            active={callStatus === "ringing"}
            completed={callStatus !== "ringing"}
          />
          <Step
            icon="phone"
            title="Contestar"
            description="Cliente en cola"
            active={callStatus === "enqueue"}
            disabled={callStatus === "ringing"}
            onClick={() => answerCall(callSidNewCall)}
          />
          <Step
            icon="headphones"
            title="Colgar"
            disabled={!isCalling && callSidNewCall}
            description="Colgar la llamada"
            onClick={handleHangup}
          />
          <Step
            icon={isMuted ? "microphone slash" : "microphone"}
            title={isMuted ? "Activar" : "Silenciar"}
            disabled={!isCalling && callSidNewCall} // Deshabilitar si no se está realizando una llamada
            onClick={isMuted ? handleUnmuteClick : handleMuteClick}
          />
        </Step.Group>
      </Container>
    </div>
  );
}

export default CallCenter;
