import axios from "./Axios";

const handleHangupClick = (callSid,idAgente) => {
    if (callSid) {
      axios
        .post(`/hang-up-call`, {
          CallSid: callSid,
          Agente: idAgente,
        })
        .then((response) => {
          // Manejar la respuesta del servicio
          //console.log('La llamada ha sido colgada:', response.data);
        })
        .catch((error) => {
          // Manejar los errores
          console.error('Error al colgar la llamada:', error);
        });
    }else{
        alert(`No hay una llamada Activa`)
    }
  };

  export { handleHangupClick };